import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Image, Text, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';

export default function CameraScreen({ onCapture, onBack }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [image, setImage] = useState(null);
  const cameraRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  if (hasPermission === null) {
    return <View><Text>Czekam na uzyskanie pozwoleń...</Text></View>;
  }
  if (hasPermission === false) {
    return <View><Text>Brak dostępu do kamery :/</Text></View>;
  }

  const takePicture = async () => {
    if (cameraRef.current) {
      const photo = await cameraRef.current.takePictureAsync();
      setImage(photo.uri);
      onCapture(photo.uri); // Assuming you have an onCapture prop to handle the captured image
    }
  };

  return (
    <View style={styles.container}>
      {image ? (
        <View>
          <Image source={{ uri: image }} style={{ width: 200, height: 200 }} />
          <TouchableOpacity onPress={() => setImage(null)} style={styles.button}>
            <Text style={styles.buttonText}>Retake</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <Camera style={styles.camera} type={type} ref={cameraRef} />
          <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={onBack} style={[styles.button, { backgroundColor: '#FF6347', borderColor: '#FF6347' }]}>
              <Text style={styles.buttonText}>Powrót</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={takePicture} style={styles.button}>
              <Text style={styles.buttonText}>Zrób zdjęcie</Text>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  camera: {
    flex: 1,
    width: '100%',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    backgroundColor: '#4CAF50', // A green shade for confirmation actions
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 3,
    borderColor: '#4CAF50', // Matching border color to the background
    borderRadius: 0, // Keeping the sharper corners for consistency
    margin: 10,
    shadowColor: 'rgba(0, 0, 0, 0.9)', // Retaining the shadow for depth
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 0,
    elevation: 4, // Maintaining elevation for the Android shadow
    fontWeight: 'bold', // Keeping the text bold
    textAlign: 'center', // Ensuring text alignment remains centered
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});
