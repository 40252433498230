import React, { useState } from 'react';
import { StyleSheet, View, Button, Text, Image, Animated, TouchableOpacity } from 'react-native';

import CameraScreen from './components/CameraScreen.js'; // Adjust the path as necessary

export default function App() {
  const [headsImage, setHeadsImage] = useState(null);
  const [tailsImage, setTailsImage] = useState(null);
  const [result, setResult] = useState('');
  const [cameraActive, setCameraActive] = useState(false);
  const [currentSide, setCurrentSide] = useState(''); // 'heads' or 'tails'

  const handleBackFromCamera = () => {
    setCameraActive(false);
    // Any other logic to return to the previous screen or hide the camera
  };
  

  const [flipAnim] = useState(new Animated.Value(0)); // Initial value for the flip animation

  const startFlipAnimation = () => {
    flipAnim.setValue(0);
    Animated.timing(flipAnim, {
      toValue: 1,
      duration: 1350,
      useNativeDriver: true, // Important for performance
    }).start();
  };
  

  const startCamera = (side) => {
    setCurrentSide(side);
    setCameraActive(true);
  };

  const onCapture = (imageUri) => {
    if (currentSide === 'heads') {
      setHeadsImage(imageUri);
    } else {
      setTailsImage(imageUri);
    }
    setCameraActive(false); // Close camera after capturing image
  };

  const tossCoin = () => {
    startFlipAnimation(); // Start the flip animation
    const tossResult = Math.random() < 0.5 ? 'heads' : 'tails';
    setResult(tossResult);
  };

  const resetGame = () => {
    setHeadsImage(null);
    setTailsImage(null);
    setResult('');
  };

  const flipInterpolate = flipAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'], // Full rotation
  });
  
  const animatedStyle = {
    transform: [{ rotateX: flipInterpolate }],
  };
  

  if (cameraActive) {
    return <CameraScreen onCapture={onCapture} onBack={handleBackFromCamera} />;
  }

  return (
    <View style={styles.container}>
      <Image source={require('./assets/animation.gif')} style={styles.coin} />
      <Image source={require('./assets/logo.png')} style={styles.logo} />

      {!headsImage && (
        <TouchableOpacity style={styles.button_new} onPress={() => startCamera('heads')}>
        <Text style={styles.buttonText}>DODAJ ZDJĘCIE: ORZEŁ</Text>
        </TouchableOpacity>
      )}
      {!tailsImage && (
        <TouchableOpacity style={styles.button_new} onPress={() => startCamera('tails')}>
          <Text style={styles.buttonText}>DODAJ ZDJĘCIE: RZESZKA</Text>
        </TouchableOpacity>
)}

      {headsImage && tailsImage && (
        <Animated.View style={[styles.imageContainer, animatedStyle]}>
        <Image source={{ uri: result === 'heads' ? headsImage : tailsImage }} style={styles.image} />
      </Animated.View>
)}

      {headsImage && tailsImage && (
        <View> 
          
          {result && (
            <Text style={styles.resultText}>
              Wynik: {result === 'heads' ? 'Orzeł' : 'Reszka'}
            </Text>
          )}
          <TouchableOpacity style={styles.button_new_green} onPress={tossCoin}>
            <Text style={styles.buttonText}>RZUĆ MONETĄ</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button_new} onPress={resetGame}>
            <Text style={styles.buttonText}>RESET</Text>
          </TouchableOpacity>

        </View>
      )}
    <Text style={styles.copyright}>© Marcin Świtalski - 2024</Text>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 250,
    height: 250,
    margin: 10,
    borderRadius: 150,
    margin: 10,
    borderWidth: 8, // Adjust the width as needed
    borderColor: '#000000', // A gold-like color for the coin's edge
  },
  heroContainer: {
    flex: 1, // Takes up all available space in its parent
    justifyContent: 'center', // Centers its children vertically
    alignItems: 'center', // Centers its children horizontally


  },
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold', // Bold text to mimic older Windows styles
  },
  copyright: {
    fontSize: 12,
    color: '#888',
    marginTop: 20,
  },
  logo: {
    resizeMode: 'contain', // Ensures the logo is scaled to fit within the bounds of the view
    width: 400, // Adjusts the logo width to be 80% of its container's width
    aspectRatio: 1, // Keeps the aspect ratio of the logo
    position: 'absolute', // Position the logo absolutely within its parent
    top: 70, // This value should be the sum of the coin's top and height, plus any desired spacing
    justifyContent: 'center', // Centers its children vertically
    alignItems: 'center', // Centers its children horizontally

  // Height is automatically adjusted based on aspectRatio
  },
  coin: {
    width: 140,
    height: 140,
    margin: 10,
    position: 'absolute', // Position the element absolutely within its parent
    top: 50, // Adjust this value as needed to position the coin from the top
    left: '50%', // Centers the element horizontally
    marginLeft: -70, // Half of the width to offset the left positioning, centers the element
  },
  
  resultText: {
    fontSize: 24,
    margin: 20,
    fontFamily: 'Comic Sans MS', // Example font family
  },
  button_new: {
    backgroundColor: '#0C54A3', // Classic Windows blue color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 3,
    borderColor: '#0C54A3',
    borderRadius: 0, // Sharper corners for a retro look
    margin: 10,
    shadowColor: 'rgba(0, 0, 0, 0.9)', // Adding shadow for a 3D effect
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 0,
    elevation: 4, // Elevation for Android shadow
    fontWeight: 'bold', // Bold text to mimic older Windows styles
    textAlign: 'center',
     // Adjust this value as needed to position the coin from the top
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 18,
    textAlign: 'center',
  },
  button_new_green: {
    backgroundColor: '#297923', // Classic Windows blue color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 3,
    borderColor: '#297923',
    borderRadius: 0, // Sharper corners for a retro look
    margin: 10,
    shadowColor: 'rgba(0, 0, 0, 0.9)', // Adding shadow for a 3D effect
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 0,
    elevation: 4, // Elevation for Android shadow
    fontWeight: 'bold', // Bold text to mimic older Windows styles
    textAlign: 'center',
},
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // set fixed postion in the middle of the screen
    top: '16%',    

  },

});
